<template>
  <div id="stations" class="auth-wrapper auth-v2">

    <div class="page-header">
      <Menus/>
      <router-link to="/" class="login">
        <fa-icon icon="home"/>
        <span>Գլխավոր</span>
      </router-link>
    </div>

    <b-row style="height: 100%" class="auth-inner m-0">
      <b-col
          lg="4"
          class="info-column"
      >
        <div class="filters">
          <div class="filter">
            <select @change="changeRegion" v-model="filters.region">
              <option value="">Ընտրեք մարզը</option>
              <option v-for="region in locations" :value="region._id">
                {{ region.name }} ({{ region_stations_count(region) }})
              </option>
            </select>
          </div>
          <div class="filter">
            <select @change="changeCounty" v-model="filters.county">
              <option value="">Ընտրեք համայնքը</option>
              <option v-for="county in counties" :value="county._id">
                {{ county.name }} ({{ county_stations_count(county) }})
              </option>
            </select>
          </div>
        </div>

        <div v-if="!$device.isMobile" id="station-regions">
          <div class="regions" id="region-items">
            <div v-for="region in locations" @click="selectRegion(region)" class="region">
              <div class="title">
                {{ region.name }}
              </div>
              <div class="count">
                {{ region_stations_count(region) }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedStation && !$device.isMobile" id="stations-info">
          <div :id="`station-item-${selectedStation._id}`" class="station">
            <div class="item-header">
              <div class="title">{{ selectedStation.name }}</div>
              <div class="icons">
                <fa-icon v-if="selectedStation.station_1" icon="car"/>
                <fa-icon v-if="selectedStation.station_2" icon="truck-moving"/>
              </div>
            </div>
            <div class="content">
              <div class="data">
                <div class="info">
                  <fa-icon icon="map-signs"></fa-icon>
                  <span v-if="!$device.isMobile" class="label">Հասցե։</span>
                  <span class="value">{{ selectedStation.region }}, {{
                      selectedStation.county
                    }}, {{ selectedStation.street }} {{ selectedStation.address }}</span>
                </div>
<!--                <div v-if="selectedStation.phone" class="info">-->
<!--                  <fa-icon icon="phone-alt"></fa-icon>-->
<!--                  <span v-if="!$device.isMobile" class="label">Հեռախոսահամար։</span>-->
<!--                  <span class="value">{{ selectedStation.phone }}</span>-->
<!--                </div>-->
<!--                <div v-if="selectedStation.boss" class="info">-->
<!--                  <fa-icon icon="portrait"></fa-icon>-->
<!--                  <span v-if="!$device.isMobile" class="label">Տնօրեն։</span>-->
<!--                  <span class="value">{{ selectedStation.boss }}</span>-->
<!--                </div>-->
<!--                <div v-if="selectedStation.tax" class="info">-->
<!--                  <fa-icon icon="credit-card"></fa-icon>-->
<!--                  <span v-if="!$device.isMobile" class="label">ՀՎՀՀ։</span>-->
<!--                  <span class="value">{{ selectedStation.tax }}</span>-->
<!--                </div>-->
<!--                <div v-if="selectedStation.bank" class="info">-->
<!--                  <fa-icon icon="money-check-alt"></fa-icon>-->
<!--                  <span v-if="!$device.isMobile" class="label">Բանկային հաշվեհամար։</span>-->
<!--                  <span class="value">{{ selectedStation.bank }}</span>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>

        <div v-if="!$device.isMobile" class="stations-count-info">
          Հայաստանում գործում է {{companies.length}} տեխզննման կայան։
        </div>

      </b-col>
      <b-col
          class="map-column"
          lg="8"
          md="8"
      >
        <yandex-map :controls="['fullscreenControl', 'geolocationControl', 'zoomControl']" :zoom="zoom"
                    class="company-map" :coords="coords">
          <ymap-marker v-for="(station, index) in stations"
                       @click="openStation(station)"
                       :icon="{color : 'darkgreen', glyph: 'auto'}"
                       :marker-id="`station-${index}`"
                       :coords="[station.latitude, station.longitude]"
          />
        </yandex-map>
      </b-col>

      <b-col v-if="$device.isMobile"  lg="8"
               md="8">
        <div v-if="selectedStation" id="stations-info">
          <div :id="`station-item-${selectedStation._id}`" class="station">
            <div class="item-header">
              <div class="title">{{ selectedStation.name }}</div>
              <div class="icons">
                <fa-icon v-if="selectedStation.station_1" icon="car"/>
                <fa-icon v-if="selectedStation.station_2" icon="truck-moving"/>
              </div>
            </div>
            <div class="content">
              <div class="data">
                <div class="info">
                  <fa-icon icon="map-signs"></fa-icon>
                  <span v-if="!$device.isMobile" class="label">Հասցե։</span>
                  <span class="value">{{ selectedStation.region }}, {{
                      selectedStation.county
                    }}, {{ selectedStation.street }} {{ selectedStation.address }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="$device.isMobile" class="stations-count-info">
          Հայաստանում գործում է {{companies.length}} տեխզննման կայան։
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import MenusModule from '@/store/menus/menusModule'
import store from '@/store'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import { BRow, BCol } from 'bootstrap-vue'
import Menus from './../../../layouts/components/Menus'

export default {
  name: 'stations',
  components: {
    BCardCode,
    yandexMap,
    ymapMarker,
    BRow,
    BCol,
    Menus
  },
  data() {
    return {
      MENUS_STORE_MODULE_NAME: 'menusModule',
      coords: [40.177682, 44.512470],
      default_coords: [40.177682, 44.512470],
      filters: {
        region: '',
        county: ''
      },
      selectedStation: null,
      counties: [],
      zoom: 8,
      region: null,
      county: null,
      stations: [],
      regions: [],
      old: false
    }
  },
  computed: {
    companies() {
      return this.$store.state[this.MENUS_STORE_MODULE_NAME].stations
    },
    locations() {
      const regions = []
      const locations = this.$store.state[this.MENUS_STORE_MODULE_NAME].locations
      locations.map(location => {
        let index = this.companies.findIndex(s => s.region === location.name)
        if (index > -1) {
          location.stations = this.companies.filter(s => s.region === location.name)
          regions.push(location)
        }
      })
      return regions
    },
    station_1_count() {
      return (region) => {
        let count = 0
        region.stations.map(station => {
          if (station.station_1) {
            count++
          }
        })
        return count
      }
    },
    station_2_count() {
      return (region) => {
        let count = 0
        region.stations.map(station => {
          if (station.station_2) {
            count++
          }
        })
        return count
      }
    },
    region_stations_count() {
      return (region) => {
        return region.stations.length
      }
    },
    county_stations_count() {
      return (county) => {
        let count = 0
        let regionIndex = this.locations.findIndex(r => r._id === county.region)
        if (regionIndex > -1) {
          count = this.companies.filter(c => c.region === this.locations[regionIndex].name && c.county === county.name).length
        }
        return count
      }
    }
  },
  created() {
    if (!store.hasModule(this.MENUS_STORE_MODULE_NAME)) store.registerModule(this.MENUS_STORE_MODULE_NAME, MenusModule)
    this.getStations()
    this.getLocations()
  },
  methods: {
    getStations() {
      this.$store.dispatch(`${this.MENUS_STORE_MODULE_NAME}/getStations`)
          .then(() => {
            this.stations = [...this.companies]
          })
    },
    getLocations() {
      this.$store.dispatch(`${this.MENUS_STORE_MODULE_NAME}/getLocations`)
          .then(() => {
            this.regions = [...this.locations]
          })
    },
    updateCoords() {
      this.stations = []
      this.selectedStation = null
      let latitude,
          longitude,
          zoom
      if (this.filters.county) {
        this.stations = this.companies.filter(s => s.county === this.county.name && s.region === this.region.name)
        if (this.stations.length === 1) {
          this.openStation(this.stations[0])
        }
        latitude = this.stations[0].latitude
        longitude = this.stations[0].longitude
        zoom = (this.$device.isMobile) ? 12 : 14
      } else if (this.filters.region) {
        this.stations = this.companies.filter(s => s.region === this.region.name)
        latitude = this.stations[0].latitude
        longitude = this.stations[0].longitude
        zoom = (this.$device.isMobile) ? 9 : 11
      } else {
        this.stations = this.companies
        latitude = this.default_coords[0]
        longitude = this.default_coords[1]
        zoom = 8
      }
      this.coords = [latitude, longitude]
      this.zoom = zoom
      const avg = this.averageGeolocation(this.stations)
      this.changeMapSettings([avg.latitude, avg.longitude], zoom)
    },
    getCounties() {
      this.county = ''
      this.counties = []
      if (this.region) {
        this.region.counties.map(county => {
          let index = this.companies.findIndex(c => c.county === county.name && c.region === this.region.name)
          if (index > -1) {
            this.counties.push(county)
          }
        })
      }
      if (this.counties.length === 1) {
        this.county = this.counties[0]
        this.filters.county = this.county._id
      }
    },
    changeRegion() {
      this.regions = this.locations
      this.filters.county = ''
      this.region = ''
      const index = this.regions.findIndex(r => r._id === this.filters.region)
      if (index > -1) {
        this.region = this.regions[index]
        this.regions = this.regions.filter(r => r.name === this.region.name)
      }
      this.getCounties()
      this.updateCoords()
    },
    selectRegion(region) {
      this.filters.region = region._id
      this.changeRegion()
    },
    changeCounty() {
      const index = this.counties.findIndex(c => c._id === this.filters.county)
      if (index > -1) {
        this.county = this.counties[index]
      }
      this.updateCoords()
    },
    selectCounty(county) {
      this.filters.county = county._id
      this.changeCounty()
    },
    toggleStation(station) {
      const accordions = document.querySelectorAll(`.station-accordion`)
      for (let accordion of accordions) {
        if (accordion.id !== `station-accordion-${station._id}`) {
          accordion.classList.remove('active')
        }
      }
      const current_accordion = document.getElementById(`station-accordion-${station._id}`)
      current_accordion.classList.toggle('active')
      this.zoom = 18
      this.coords = [station.latitude, station.longitude]
      const topPos = document.getElementById(`station-accordion-${station._id}`).offsetTop
      document.getElementById('stations-items').scrollTop = topPos - 10
      this.changeMapSettings([station.latitude, station.longitude], 18)
    },
    changeMapSettings(coords, zoom) {
      setTimeout(() => {
        this.coords = coords
        this.zoom = zoom
      }, 0)
    },
    openMarker(station) {
      this.toggleStation(station)
    },
    openStation(station) {
      this.selectedStation = station
      // this.zoom = 18
      // this.coords = [station.latitude, station.longitude]
      // this.changeMapSettings([station.latitude, station.longitude], 18)
    },

    averageGeolocation(coords) {
      if (coords.length === 1) {
        return coords[0];
      }

      let x = 0.0;
      let y = 0.0;
      let z = 0.0;

      for (let coord of coords) {
        let latitude = coord.latitude * Math.PI / 180;
        let longitude = coord.longitude * Math.PI / 180;

        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
      }

      let total = coords.length;

      x = x / total;
      y = y / total;
      z = z / total;

      let centralLongitude = Math.atan2(y, x);
      let centralSquareRoot = Math.sqrt(x * x + y * y);
      let centralLatitude = Math.atan2(z, centralSquareRoot);

      return {
        latitude: centralLatitude * 180 / Math.PI,
        longitude: centralLongitude * 180 / Math.PI
      };
    }
  }
}
</script>

<style lang="scss">
#stations {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .map-column {
    height: 100%;
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;

    .login {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      font-weight: 600;
      margin-top: 5px;
      border-bottom: 2px solid #39a164;
      padding: 5px;

      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .filters {
    display: flex;
    background: #fff;
    padding: 10px;
    margin-top: 1rem;

    .filter {
      display: flex;
      margin-right: 2rem;
      width: 50%;

      select {
        width: 100%;
        text-align: center;
        border: 1px solid #d8d6de;
        padding: 9px;
        background: #f6f6f6;
        border-radius: 4px;
      }
    }
  }

  .company-map {
    width: 100%;
    margin-top: 1rem;
    height: calc(100vh - 80px);
  }

  #stations-info {
    max-height: calc(100vh - 70px);
    overflow-y: auto;
    margin-top: 1rem;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.085);

    .station {
      border-bottom: 1px solid rgba(0, 0, 0, 0.085);

      .item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 0 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.085);

        .title {
          color: #000;
          font-weight: bold;
          font-size: 0.9rem;
        }

        .icons {
          display: flex;

          svg {
            margin-left: 10px;
            color: #000000;
            font-size: 19px;

            &.fa-car {
              font-size: 22px;
              position: relative;
              top: -1px;
            }
          }
        }
      }

      .content {
        margin: 0;
        padding: 5px 15px;
        background: #fff;

        .data {
          display: flex;
          flex-direction: column;
          width: 100%;

          .info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 30px;
            color: #000;

            svg {
              margin-right: 1rem;
              width: 1rem;
            }

            .label {
              color: #8d8d8d;
              margin-right: 0.5rem;
            }

            .value {
              font-weight: bold;
              font-size: 0.9rem;
            }
          }
        }
      }

      &.active {
        background: #39a164;

        .content {
          display: flex;
        }
      }
    }
  }

  #station-regions {
    display: flex;
    flex-direction: column;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.085);
    margin-top: 1rem;

    .regions {
      display: flex;
      flex-direction: column;

      .region {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        height: 40px;
        align-items: center;
        padding: 0 15px;

        .title {
          color: #000;
          font-weight: bold;
          font-size: 0.9rem;
        }

        .count {
          color: #000;
          font-weight: bold;
        }

        .stations {
          display: flex;

          .station {
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              color: #000;
              font-size: 19px;

              &.fa-car {
                font-size: 19px;
              }
            }

            span {
              &.count {
                color: #000000;
                font-weight: bold;
                margin-left: 10px;
                font-size: 0.9rem;
              }
            }
          }
        }
      }


      .station_content {
        padding: 15px;
        padding-left: 5px;

        .data {
          .info {
            margin-bottom: 5px;
            color: #000;
            font-weight: bold;
            font-size: 0.9rem;

            svg {
              width: 30px;
            }

            span {
              &.label {
                margin-right: 10px;
                color: #7d7d7d;
              }

              &.title {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

}

@media (max-width: 768px) {

  #stations {
    height: auto;

    .info-column {
      padding: 0!important;
    }

    .filters {
      background: none;
      .filter {
        margin-right: 1rem !important;

        &:last-child {
          margin-right: 0 !important;
        }
      }
    }

    .map-column {
      height: auto;
    }

    #stations-info {
      .station {
        .item-header {
          height: auto;
          padding: 10px 15px;
        }
        .content {
          .data {
            .info {
              height: auto;
              margin: 5px 0;
            }
          }
        }
      }
    }

    .company-map {
      margin-top: 0;
      height: 275px;
    }

    #stations-accordion {
      margin-right: 0;

      .items {
        max-height: 300px;

        .item {
          .station {
            padding: 0 10px;
          }

          .item-header {
            .title {
              font-size: 11px;
            }

            .icons {
              svg {
                font-size: 15px !important;

                &.fa-car {
                  font-size: 18px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.stations-count-info {
  background: #f6f6f6;
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.085);
  color: #000;
  font-weight: bold;
  padding: 15px 10px;
  text-align: center;
  font-size: 15px;
}
</style>
